<template>
<div style="width:100%;height:100%;background-image: url('/imgs/mcv-light.jpg');background-size:cover;">
   <div style="width:100%;height:100%;background-color:#0000008c;"> 
      <v-container 
         style="max-width:2600px;"
         class="pa-0">
         <v-row style="" class="ma-0 pa-0 pa-1">
            <v-col cols="12" class="px-1 py-1">
               <v-card class="pa-0" style="background-color:#FFFFFF30;border:1.5px solid white;border-radius:6px;">
                  <v-card-title>
                     <div style="display:inline-block;font-size:18px;color:white;">
                        Đèn đang mở:
                     </div>
                     <div class="ml-3" 
                        style="display:inline-block;font-size:38px;color:yellow;font-weight:700;">
                        {{numOfLightOn}}
                     </div>
                     <v-spacer/>
                     <v-btn to="/light/report"
                        color="white"
                        class="text-none px-1"
                        text>
                        <span style="font-weight:700;">Xem lịch sử</span>
                     </v-btn>
                     <v-btn to="/light/setting"
                        color="white"
                        class="text-none px-1"
                        icon>
                        <v-icon>
                           mdi-cog
                        </v-icon>
                     </v-btn>
                  </v-card-title>
               </v-card>
            </v-col>

            <v-col cols="12" class="px-1 py-1">
               <v-card class="pa-0" style="background-color:#FFFFFF05;border-bottom:1px solid #88888888;border-radius:0px;">
                  <v-btn tile dark text
                     :color="activeFloor==3?'cyan':'white'"
                     @click="activeFloor=3"
                     class="text-none px-1">
                     <span style="font-size:13px;">
                     Tầng Trệt
                     </span>
                  </v-btn>
                  <v-btn dark tile text
                     :color="activeFloor==2?'cyan':'white'"
                     @click="activeFloor=2"
                     class="text-none px-1">
                     <span style="font-size:13px;">
                     Tầng 2
                     </span>
                  </v-btn>
                  <v-btn dark tile text
                     :color="activeFloor==1?'cyan':'white'"
                     @click="activeFloor=1"
                     class="text-none px-1">
                     <span style="font-size:13px;">
                     Tầng 3
                     </span>
                  </v-btn>
                  <v-btn dark tile text
                     :color="activeFloor==0?'cyan':'white'"
                     @click="activeFloor=0"
                     class="text-none px-1">
                     <span style="font-size:13px;">
                     Tầng 4
                     </span>
                  </v-btn>
                  <v-btn dark tile text
                     :color="activeFloor==-1?'cyan':'white'"
                     @click="activeFloor=-1"
                     class="text-none px-1">
                     <span style="font-size:13px;">
                     Tất cả
                     </span>
                  </v-btn>
               </v-card>
            </v-col>

            <template v-for="(floor, index) in floors">

               <v-col v-if="$vuetify.breakpoint.mdAndUp && (activeFloor==-1 || index==activeFloor)" 
                  :key="index"
                  cols="12"
                  class="pb-4 px-1">
                  <div class="light-floor">
                     <div class="light-floor-title">
                        {{floor.name}}
                     </div>
                     
                     <template v-if="index>0">
                        <div 
                           style="position:absolute;bottom: 0;left:22vw;right:22vw;font-size:13px;line-height:20px;color:#DDDDDD;font-weight:700;;text-align:center;background-color:#F57C0088;">
                           Cổng chính
                        </div>

                        <div class="pt-6"
                           style="position:absolute;bottom: 22px;height:28%;left:35vw;right:0vw;font-size:13px;line-height:20px;color:#DDDDDD;font-weight:700;;text-align:center;border:1px solid white;background-color:#FFFFFF22;">
                           Văn phòng
                        </div>

                        <div class="pt-10" v-if="index>1"
                           style="position:absolute;bottom: 36.5%;top:38%;left:0;right:77.6%;font-size:13px;line-height:20px;color:#DDDDDD;font-weight:700;;text-align:center;border:1px solid white;background-color:#FFFFFF22;">
                           Canteen
                        </div>
                     </template>

                     <template v-for="(light, lIndex) in floor.lights" >
                        <div 
                           v-if="light.name" :key="lIndex"
                           :style="{
                              'top': light.top,
                              'left': light.left,
                           }"
                           style="position:absolute;display:inline-block;width:5.8vw;height:5.8vw;border-radius:8px;background-color:#FFFFFF22;">
                           
                           
                           <div style="position:relative:width:100%;height: 5vw;">
                              <div class="pt-1"
                                 style="position:absolute;top:0.2vw;left:0;right:0;color:white;font-size:0.8vw;text-align:center;color:#EEEEEE;">
                                 {{light.name}}
                              </div>
                              <div style="position:absolute;top:1.6vw;left:0;right:0;"
                                 v-if="light.status.value!=null"
                                 class="text-center">
                                 <v-icon v-if="light.status.value"
                                    class="pt-1" 
                                    color="yellow"
                                    style="font-size:2.4vw;">
                                    mdi-lightbulb-on
                                 </v-icon>
                                 <v-icon v-else
                                    class="pt-3" 
                                    color="#AAAAAAAA"
                                    style="font-size:2.0vw;">
                                    mdi-lightbulb-outline
                                 </v-icon>
                              </div>
                              <div v-if="light.mode.value!=0 && light.status.value!=null"
                                 style="position:absolute;top:3.8vw;left:0.3vw;">
                                 <v-icon 
                                    :color="resolveLightModeColor(light.mode.value)"
                                    style="font-size:1.2vw;">
                                    mdi-lock
                                 </v-icon>
                              </div>
                              <div v-if="light.status.value!=null"
                                 style="position:absolute;top:3.6vw;right:-0.2vw;">
                                 <v-menu offset-y>
                                    <template v-slot:activator="{ on, attrs }">
                                       <v-btn
                                          icon
                                          dark
                                          v-bind="attrs"
                                          v-on="on"
                                          color="white"
                                          class="text-none px-1"
                                          style="min-width:30px;width:20px;height:30px;"
                                       >
                                          <v-icon style="font-size:1.2vw;">
                                             mdi-dots-vertical
                                          </v-icon>
                                       </v-btn>
                                    </template>
                                    <v-list>
                                       <v-list-item-group>
                                          <template v-if="light.mode.value==0">
                                             <v-list-item 
                                                v-if="!light.status.value"
                                                @click="turnOnLight(light.exCtrl.pointName)">
                                                <v-list-item-title>Mở đèn</v-list-item-title>
                                             </v-list-item>
                                             <v-list-item 
                                                v-if="light.status.value"
                                                @click="turnOffLight(light.exCtrl.pointName)">
                                                <v-list-item-title>Tắt đèn</v-list-item-title>
                                             </v-list-item>
                                             <v-divider/>
                                          </template>
                                          
                                          <v-list-item 
                                             v-if="light.mode.value!=0"
                                             @click="callSetPoint(light.mode.pointName, 0)">
                                             <v-list-item-title>Điều khiển tự do</v-list-item-title>
                                          </v-list-item>
                                          <v-list-item 
                                             v-if="light.mode.value!=1"
                                             @click="callSetPoint(light.mode.pointName, 1)">
                                             <v-list-item-title>Giữ luôn mở</v-list-item-title>
                                          </v-list-item>
                                          <v-list-item 
                                             v-if="light.mode.value!=-1"
                                             @click="callSetPoint(light.mode.pointName, -1)">
                                             <v-list-item-title>Giữ luôn tắt</v-list-item-title>
                                          </v-list-item>
                                       </v-list-item-group>
                                    </v-list>
                                 </v-menu>
                              </div>
                           </div>
                        </div>
                     </template>
                  </div>
               </v-col>

               <v-col v-if="$vuetify.breakpoint.smAndDown && (activeFloor==-1 || index==activeFloor)"
                  cols="12"
                  class="pb-4 px-1"
                  :key="index">
                  <div class="light-floor-mobile">
                     <div class="light-floor-title-mobile">
                        {{floor.name}}
                     </div>
                     <div class="pa-1">
                        <template v-for="(light, lIndex) in floor.lights" >
                           <div v-if="light.name" :key="lIndex"
                              class="pa-1"
                              style="display:inline-block;vertical-align:top;width:33%;height:92px;">
                              <div style="position:relative;width:100%;height:100%;border-radius:8px;background-color:#FFFFFF22;">
                                 <div class="pt-1"
                                    style="position:absolute;top:1px;left:0;right:0;color:white;font-size:12px;text-align:center;color:#EEEEEE;">
                                    {{light.name}}
                                 </div>
                                 <div style="position:absolute;top:22px;left:0;right:0;"
                                    v-if="light.status.value!=null"
                                    class="text-center">
                                    <v-icon v-if="light.status.value"
                                       class="pt-1" 
                                       color="yellow"
                                       style="font-size:36px">
                                       mdi-lightbulb-on
                                    </v-icon>
                                    <v-icon v-else
                                       class="pt-3" 
                                       color="#AAAAAAAA"
                                       style="font-size:32px">
                                       mdi-lightbulb-outline
                                    </v-icon>
                                 </div>
                                 <div v-if="light.mode.value!=0 && light.status.value!=null"
                                    style="position:absolute;top:52px;left:6px;">
                                    <v-icon 
                                       :color="resolveLightModeColor(light.mode.value)"
                                       style="font-size:18px;">
                                       mdi-lock
                                    </v-icon>
                                 </div>
                                 <div v-if="light.status.value!=null"
                                    style="position:absolute;top:52px;right:4px;">
                                    <v-menu offset-y>
                                       <template v-slot:activator="{ on, attrs }">
                                          <v-btn
                                             icon
                                             dark
                                             v-bind="attrs"
                                             v-on="on"
                                             color="white"
                                             class="text-none px-1"
                                             style="min-width:30px;width:20px;height:30px;"
                                          >
                                             <v-icon style="font-size:18px;">
                                                mdi-dots-vertical
                                             </v-icon>
                                          </v-btn>
                                       </template>
                                       <v-list>
                                          <v-list-item-group>
                                             <template v-if="light.mode.value==0">
                                                <v-list-item 
                                                   v-if="!light.status.value"
                                                   @click="turnOnLight(light.exCtrl.pointName)">
                                                   <v-list-item-title>Mở đèn</v-list-item-title>
                                                </v-list-item>
                                                <v-list-item 
                                                   v-if="light.status.value"
                                                   @click="turnOffLight(light.exCtrl.pointName)">
                                                   <v-list-item-title>Tắt đèn</v-list-item-title>
                                                </v-list-item>
                                                <v-divider/>
                                             </template>
                                             
                                             <v-list-item 
                                                v-if="light.mode.value!=0"
                                                @click="callSetPoint(light.mode.pointName, 0)">
                                                <v-list-item-title>Điều khiển tự do</v-list-item-title>
                                             </v-list-item>
                                             <v-list-item 
                                                v-if="light.mode.value!=1"
                                                @click="callSetPoint(light.mode.pointName, 1)">
                                                <v-list-item-title>Giữ luôn mở</v-list-item-title>
                                             </v-list-item>
                                             <v-list-item 
                                                v-if="light.mode.value!=-1"
                                                @click="callSetPoint(light.mode.pointName, -1)">
                                                <v-list-item-title>Giữ luôn tắt</v-list-item-title>
                                             </v-list-item>
                                          </v-list-item-group>
                                       </v-list>
                                    </v-menu>
                                 </div>
                              </div>
                           </div>
                           <div v-else :key="lIndex+100"
                              class="mt-2 pl-4" 
                              style="border-bottom: 1px solid #FFFFFF66;color:#EEEEEE;font-size:16px;">
                              <b>{{light.label}}</b>
                           </div>
                        </template>
                     </div>
                  </div>
               </v-col>
            </template>
         </v-row>
      </v-container>
   </div>
</div>
</template>

<script>
export default {
   data () {
      return {
         socketAskKey: new Date().getTime(),
         numOfLightOn: 0,
         numOfLight: 17,
         activeFloor: 3,
         records: [],
         roles: ['light-manager', 'admin'],
         floors: [
            {
               name: "Tầng 4",
               lights: [
                  {
                     name: "",
                     label: "Khu Văn Phòng"
                  },
                  {
                     name: "Hành lang",
                     top: '30%',
                     left: "50%",
                     status: {pointName: 'light_vp_L6', value: null},
                     exCtrl: {pointName: 'light_vp_L6_btn_exctrl', value: null},
                     mode: {pointName: 'light_vp_L6_mode', value: null},
                     button: {pointName: 'light_vp_L6_btn', value: null},
                  },
                  {
                     name: "Thang bộ",
                     top: '30%',
                     left: "88%",
                     status: {pointName: 'light_vp_L5', value: null},
                     exCtrl: {pointName: 'light_vp_L5_btn_exctrl', value: null},
                     mode: {pointName: 'light_vp_L5_mode', value: null},
                     button: {pointName: 'light_vp_L5_btn', value: null},
                  }
               ]
            },
            {
               name: "Tầng 3",
               lights: [
                  {
                     name: "",
                     label: "Khu Văn Phòng"
                  },
                  {
                     name: "Hành Lang",
                     top: '40%',
                     left: "70%",
                     status: {pointName: 'light_vp_L8', value: null},
                     exCtrl: {pointName: 'light_vp_L8_btn_exctrl', value: null},
                     mode: {pointName: 'light_vp_L8_mode', value: null},
                     button: {pointName: 'light_vp_L8_btn', value: null},
                  },
                  {
                     name: "Thang bộ",
                     top: '40%',
                     left: "88%",
                     status: {pointName: 'light_vp_L7', value: null},
                     exCtrl: {pointName: 'light_vp_L7_btn_exctrl', value: null},
                     mode: {pointName: 'light_vp_L7_mode', value: null},
                     button: {pointName: 'light_vp_L7_btn', value: null},
                  },
                  {
                     name: "",
                     label: "Khu Chung"
                  },
                  {
                     name: "Giếng trời",
                     top: '40%',
                     left: "45%",
                     status: {pointName: 'light_gxe_L4', value: null},
                     exCtrl: {pointName: 'light_gxe_L4_btn_exctrl', value: null},
                     mode: {pointName: 'light_gxe_L4_mode', value: null},
                     button: {pointName: 'light_gxe_L4_btn', value: null},
                  },
                  {
                     name: "",
                     label: "Love Studio"
                  },
                  {
                     name: "Cầu thang",
                     top: '10%',
                     left: "10%",
                     status: {pointName: 'light_gxe_L3', value: null},
                     exCtrl: {pointName: 'light_gxe_L3_btn_exctrl', value: null},
                     mode: {pointName: 'light_gxe_L3_mode', value: null},
                     button: {pointName: 'light_gxe_L3_btn', value: null},
                  },
                  {
                     name: "",
                     label: "Khu Cổng Chính"
                  },
                  {
                     name: "Thang bộ",
                     top: '72%',
                     left: "5%",
                     status: {pointName: 'light_bv_L7', value: null},
                     exCtrl: {pointName: 'light_bv_L7_btn_exctrl', value: null},
                     mode: {pointName: 'light_bv_L7_mode', value: null},
                     button: {pointName: 'light_bv_L7_btn', value: null},
                  },
                  {
                     name: "Hàng lang",
                     top: '72%',
                     left: "15%",
                     status: {pointName: 'light_bv_L6', value: null},
                     exCtrl: {pointName: 'light_bv_L6_btn_exctrl', value: null},
                     mode: {pointName: 'light_bv_L6_mode', value: null},
                     button: {pointName: 'light_bv_L6_btn', value: null},
                  },
               ]
            },
            {
               name: "Tầng 2",
               lights: [
                  {
                     name: "",
                     label: "Khu Văn Phòng"
                  },
                  {
                     name: "Hành Lang VP",
                     top: '40%',
                     left: "70%",
                     status: {pointName: 'light_vp_L4', value: null},
                     exCtrl: {pointName: 'light_vp_L4_btn_exctrl', value: null},
                     mode: {pointName: 'light_vp_L4_mode', value: null},
                     button: {pointName: 'light_vp_L4_btn', value: null},
                  },
                  {
                     name: "Thang Trên",
                     top: '27%',
                     left: "88%",
                     status: {pointName: 'light_vp_L7', value: null},
                     exCtrl: {pointName: 'light_vp_L7_btn_exctrl', value: null},
                     mode: {pointName: 'light_vp_L7_mode', value: null},
                     button: {pointName: 'light_vp_L7_btn', value: null},
                  },
                  {
                     name: "Thang Dưới",
                     top: '46%',
                     left: "88%",
                     status: {pointName: 'light_vp_L1', value: null},
                     exCtrl: {pointName: 'light_vp_L1_btn_exctrl', value: null},
                     mode: {pointName: 'light_vp_L1_mode', value: null},
                     button: {pointName: 'light_vp_L1_btn', value: null},
                  },
                  {
                     name: "",
                     label: "Khu Love Studio"
                  },
                  {
                     name: "Cầu thang",
                     top: '10%',
                     left: "5%",
                     status: {pointName: 'light_gxe_L2', value: null},
                     exCtrl: {pointName: 'light_gxe_L2_btn_exctrl', value: null},
                     mode: {pointName: 'light_gxe_L2_mode', value: null},
                     button: {pointName: 'light_gxe_L2_btn', value: null},
                  },
                  {
                     name: "Hàng lang",
                     top: '10%',
                     left: "15%",
                     status: {pointName: 'light_gxe_L5', value: null},
                     exCtrl: {pointName: 'light_gxe_L5_btn_exctrl', value: null},
                     mode: {pointName: 'light_gxe_L5_mode', value: null},
                     button: {pointName: 'light_gxe_L5_btn', value: null},
                  },
                  {
                     name: "",
                     label: "Khu Cổng Chính"
                  },
                  {
                     name: "Hàng lang",
                     top: '72%',
                     left: "12%",
                     status: {pointName: 'light_bv_L4', value: null},
                     exCtrl: {pointName: 'light_bv_L4_btn_exctrl', value: null},
                     mode: {pointName: 'light_bv_L4_mode', value: null},
                     button: {pointName: 'light_bv_L4_btn', value: null},
                  },
               ]
            },
            {
               name: "Tầng Trệt",
               lights: [
                  {
                     name: "",
                     label: "Khu Văn Phòng"
                  },
                  {
                     name: "HL Toilet",
                     top: '9%',
                     left: "79%",
                     status: {pointName: 'light_vp_L3', value: null},
                     exCtrl: {pointName: 'light_vp_L3_btn_exctrl', value: null},
                     mode: {pointName: 'light_vp_L3_mode', value: null},
                     button: {pointName: 'light_vp_L3_btn', value: null},
                  },
                  {
                     name: "Hành Lang",
                     top: '40%',
                     left: "70%",
                     status: {pointName: 'light_vp_L2', value: null},
                     exCtrl: {pointName: 'light_vp_L2_btn_exctrl', value: null},
                     mode: {pointName: 'light_vp_L2_mode', value: null},
                     button: {pointName: 'light_vp_L2_btn', value: null},
                  },
                  {
                     name: "Thang bộ",
                     top: '40%',
                     left: "88%",
                     status: {pointName: 'light_vp_L1', value: null},
                     exCtrl: {pointName: 'light_vp_L1_btn_exctrl', value: null},
                     mode: {pointName: 'light_vp_L1_mode', value: null},
                     button: {pointName: 'light_vp_L1_btn', value: null},
                  },
                  {
                     name: "",
                     label: "Khu Love Studio"
                  },
                  {
                     name: "Thang bộ",
                     top: '10%',
                     left: "5%",
                     status: {pointName: 'light_gxe_L2', value: null},
                     exCtrl: {pointName: 'light_gxe_L2_btn_exctrl', value: null},
                     mode: {pointName: 'light_gxe_L2_mode', value: null},
                     button: {pointName: 'light_gxe_L2_btn', value: null},
                  },
                  {
                     name: "Hàng lang",
                     top: '10%',
                     left: "15%",
                     status: {pointName: 'light_gxe_L6', value: null},
                     exCtrl: {pointName: 'light_gxe_L6_btn_exctrl', value: null},
                     mode: {pointName: 'light_gxe_L6_mode', value: null},
                     button: {pointName: 'light_gxe_L6_btn', value: null},
                  },
                  {
                     name: "",
                     label: "Khu Cổng Chính"
                  },
                  {
                     name: "Thang bộ Lửng",
                     top: '65%',
                     left: "5%",
                     status: {pointName: 'light_bv_L5', value: null},
                     exCtrl: {pointName: 'light_bv_L5_btn_exctrl', value: null},
                     mode: {pointName: 'light_bv_L5_mode', value: null},
                     button: {pointName: 'light_bv_L5_btn', value: null},
                  },
                  {
                     name: "Hàng lang Lửng",
                     top: '65%',
                     left: "15%",
                     status: {pointName: 'light_bv_L3', value: null},
                     exCtrl: {pointName: 'light_bv_L3_btn_exctrl', value: null},
                     mode: {pointName: 'light_bv_L3_mode', value: null},
                     button: {pointName: 'light_bv_L3_btn', value: null},
                  },
                  {
                     name: "Thang bộ Trệt",
                     top: '83%',
                     left: "5%",
                     status: {pointName: 'light_bv_L2', value: null},
                     exCtrl: {pointName: 'light_bv_L2_btn_exctrl', value: null},
                     mode: {pointName: 'light_bv_L2_mode', value: null},
                     button: {pointName: 'light_bv_L2_btn', value: null},
                  },
                  {
                     name: "Hàng lang Trệt",
                     top: '83%',
                     left: "15%",
                     status: {pointName: 'light_bv_L1', value: null},
                     exCtrl: {pointName: 'light_bv_L1_btn_exctrl', value: null},
                     mode: {pointName: 'light_bv_L1_mode', value: null},
                     button: {pointName: 'light_bv_L1_btn', value: null},
                  },
                  {
                     name: "Lối vào",
                     top: '60%',
                     left: "30%",
                     status: {pointName: 'light_gxe_L1', value: null},
                     exCtrl: {pointName: 'light_gxe_L1_btn_exctrl', value: null},
                     mode: {pointName: 'light_gxe_L1_mode', value: null},
                     button: {pointName: 'light_gxe_L1_btn', value: null},
                  },
               ]
            }
         ]
      }
   },
   sockets: {
      reportLastValue: function (payload) {
         if(payload && payload.devCode == this.$root.devCode){
            this.records = payload.content

            // console.log(this.records)
            this.floors.forEach(floor => {
               floor.lights.forEach(light => {
                  if(light.status){
                     this.records.forEach(rec => {
                        if(rec.reportName == light.status.pointName) {
                           light.status.value = rec.value
                        }
                        if(rec.reportName == light.exCtrl.pointName) {
                           light.exCtrl.value = rec.value
                        }
                        if(rec.reportName == light.mode.pointName) {
                           light.mode.value = rec.value
                        }
                        if(rec.reportName == light.button.pointName) {
                           light.button.value = rec.value
                        }
                     })
                  }
               })
            })
            this.numOfLightOn =  this.countNumOfLightOn()
            this.$forceUpdate()
         }

      },
      callHTTPRequestResult: function (payload) {
         console.log("callHTTPRequestResult")
         console.log(payload)
         if(payload && payload.result && payload.result.status){
            if(payload.result.status.startsWith("ERR")){
               alert(payload.result.message)
            }
         }
      }
   },
   methods: {
      countNumOfLightOn: function(){
         let num = 0
         let listExist = []
         this.floors.forEach(floor => {
            floor.lights.forEach(light => {
               if(light.status){
                  if( !listExist.includes(light.status.pointName)){
                  if(light.status.value) num++
                     listExist.push(light.status.pointName)
                  }
               }
            })
         })
         return num
      },
      requestLastData(){
         this.$socket.emit('requestLastValue', 
         { 
            devCode: this.$root.devCode,
            askKey: this.socketAskKey
         })
      },
      resolveLightModeText(mode){
         if(mode==0) return "ĐK tự do"
         if(mode==-1) return "Luôn tắt"
         if(mode==1) return "Luôn mở"
         return ""
      },
      resolveLightModeColor(mode){
         if(mode==-1) return "red"
         if(mode==1) return "#76FF03"
         return "white"
      },
      turnOnLight (reportName) {
         let self = this
         self.callSetPoint(reportName, 0)
         setTimeout(function(){
            self.callSetPoint(reportName, 1)
         }, 500)
      },
      turnOffLight (reportName) {
         let self = this
         self.callSetPoint(reportName, 1)
         setTimeout(function(){
            self.callSetPoint(reportName, 0)
         }, 500)
      },
      callSetPoint (reportName, value){
         let path = this.$root.apiGate+ 'setPoints'
         let payload = {
            points: [
               {
                  reportName: reportName,
                  value: value
               }
            ]
         }
         this.callApiOverOTA("POST", path, payload)
      },
      callApiOverOTA (method, url, body) {
         this.$socket.emit('callHTTPRequest', 
         { 
            devCode: this.$root.devCode,
            askKey: this.socketAskKey,
            method: method,
            url: url,
            body: body
         })
         this.requestLastDataAfter1Sec()
      },
      requestLastDataAfter1Sec(){
         let self = this
         setTimeout(function(){
            self.requestLastData()
         }, 1000)
      }
   },
   mounted() {
      this.requestLastData()
   },
}
</script>

<style>
   .light-floor {
      position:relative;
      width:100%;
      height:540px;
      border:2px solid white;
      border-radius: 12px;
   }

   .light-floor-title {
      position:absolute;
      top:6px;
      left:8px;
      display:inline-block;
      width:100px;
      font-size:1.4vw;
      font-weight:700;
      color:cyan;
   }

   .light-floor-mobile {
      width:100%;
      border:1px solid white;
      border-radius: 8px;
   }

   .light-floor-title-mobile {
      font-size:  16px;
      font-weight:700;
      color:cyan;
      text-align: center;
      border-bottom: 1px solid #EEEEEE;
   }
</style>